<template>
  <div>
    <h1 style="color: red">Attention! Temporary the Correction Request is Downtime!</h1>
    <br />
    <h1>Dear User,</h1>
    <br />
    <h1>
      We apologize for any inconvenience. Stay tuned for the updates once we're back
      online!
    </h1>
    <br />
    <h1>🌐 New Website Link: [http://sys-com_svr/hrdinquiry/login]</h1>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
